import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {ItemDashboard, ItemDomains, ItemTransactions, ItemPrices, ItemContacts, ItemClients} from './mainListItem';

const styles = theme => ({
    categoryHeader: {
        paddingTop: 16,
        paddingBottom: 16,
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 4,
        paddingBottom: 4,
        color: 'rgba(255, 255, 255, 0.7)',
    },
    itemCategory: {
        backgroundColor: '#343a40',
        // boxShadow: '0 -1px 0 #F6F6F6 inset',
        paddingTop: 20,
        paddingBottom: 20,
    },
    firebase: {
        fontSize: 24,
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.common.white,
    },
    itemActionable: {
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemActiveItem: {
        color: '#05092C',
    },
    itemPrimary: {
        color: 'inherit',
        fontSize: theme.typography.fontSize,
        '&$textDense': {
            fontSize: theme.typography.fontSize,
        },
    },
    textDense: {},
    divider: {
        marginTop: theme.spacing(2),
    },
    logo: {
        maxWidth: "200px"
    }
});

function Navigator(props) {
    const {classes, ...other} = props;

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.item, classes.itemCategory)}>
                    <img src={"/img/logo.png"} className={classes.logo} alt={"resell go"}/>
                </ListItem>
            </List>
            <List><ItemDashboard language={props.language}/></List>
            <List><ItemClients language={props.language}/></List>
            <List><ItemContacts language={props.language}/></List>
            <List><ItemDomains language={props.language}/></List>
            <List><ItemPrices language={props.language}/></List>
            <List><ItemTransactions language={props.language}/></List>
        </Drawer>
    );
}

Navigator.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);