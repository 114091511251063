import {
    CHANGE_MOD_PROD,
    CHANGE_LANGUAGE,
    CHANGE_CURRENCY,
    userConstants, CHANGE_ORDER,
    CHANGE_ORDER_ITEM
} from './nameActions'
import {serviceApiResponse} from "../../services/request";


///Aqui se empiezan a declarar las acciones globales de la aplicación,
export const changeModProd = (value) => ({
    type: CHANGE_MOD_PROD,
    value
});

export const changeLanguage = (value) => ({
    type: CHANGE_LANGUAGE,
    value
});

export const changeCurrency = (value) => ({
    type: CHANGE_CURRENCY,
    value
});

export const changeOrder = (value) => ({
    type: CHANGE_ORDER,
    value
});

export const loginSuccess = (user) => ({
    type: userConstants.LOGIN_SUCCESS,
    user
});


export const requestChangeOrder = value => {
    return dispatch => {
        sessionStorage.setItem("order", JSON.stringify(value));
        dispatch(changeOrder(value));
    }
};

export const requestChangeOrderItem = (value) => ({
    type: CHANGE_ORDER_ITEM,
    value
});

export const requestGetOrder = order => {
    if (order) {
        return dispatch => {
            serviceApiResponse({}, `orders/${order.id}`, "GET", false, false)
                .then(response => {
                    dispatch(requestChangeOrder(response));
                    dispatch(requestChangeOrderItem(response.items.length));
                })
        }
    }
}