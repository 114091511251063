import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SimpleReactValidator from "simple-react-validator";
import {changeContact, changeContactInput} from '../store/actions/contactsActions';
import {serviceApiResponse} from "../services/request";

const mapStateToProps = ({global: {language}, contact: {contact}}) => ({
    contact,
    language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeContactInput,
    changeContact,
}, dispatch);


export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                editPrice: {
                    id: 0,
                },
                loading: false,
                loadingPage: true,
            };

            this.validator = new SimpleReactValidator(
                {
                    messages: {
                        required: "El campo es requerido",
                        string: "El campo debe tener mínimo 3 caracteres",
                        numeric: "El campo debe ser un valor númerico",
                        regex: "El email no es valido"
                    },
                }
            );

            this.handleInputChange = this.handleInputChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleSubmit(e) {
            e.preventDefault();
            const id = window.location.pathname.split("/").slice(-1).pop();

            const {editPrice} = this.state;
            let hasPromo = editPrice.promoFrom || editPrice.promoTo || editPrice.createPromoValue || editPrice.renewPromoValue || editPrice.transferPromoValue || editPrice.restorePromoValue ? true : false;
            const data = {
                "createValue": parseFloat(editPrice.createValue),
                "renewValue": parseFloat(editPrice.renewValue),
                "transferValue": parseFloat(editPrice.transferValue),
                "restoreValue": parseFloat(editPrice.restoreValue),
                "hasPromo": hasPromo,
                "promoFrom": parseFloat(editPrice.promoFrom),
                "promoTo": parseFloat(editPrice.promoTo),
                "createPromoValue": parseFloat(editPrice.createPromoValue),
                "renewPromoValue": parseFloat(editPrice.renewPromoValue),
                "transferPromoValue": parseFloat(editPrice.transferPromoValue),
                "restorePromoValue": parseFloat(editPrice.restorePromoValue)
            };

            if (this.validator.allValid()) {
                this.setState({loading: true});

                serviceApiResponse(data, `tld_prices/${id}`, `PUT`)
                    .then(response => {
                        if (response.violations || response.detail) {
                            this.props.handleMessage(response.detail, "error")
                        } else {
                            this.props.handleMessage(`Actualizado exitosamente`, "success");
                            this.props.history.push("/Prices");
                        }
                        this.setState({loading: false});
                    }).catch(error => {
                    console.log(error);
                    this.setState({loading: false});
                    this.props.handleMessage(error.message, "error")
                })
            } else {
                this.validator.showMessages();
                this.forceUpdate();
            }
        }

        handleInputChange(e) {
            const name = e.target.name;
            const value = e.target.value;
            const {editPrice} = this.state;

            this.setState({editPrice: {...editPrice, [name]: value}});

        }

        componentDidMount() {
            const id = window.location.pathname.split("/").slice(-1).pop();

            this.setState({loadingPage: false});

            this.setState({loadingPage: true});
            serviceApiResponse({}, `tld_prices/${id}`, "GET")
                .then(response => {
                    if (response.id) {
                        this.setState({editPrice: response, loadingPage: false});
                    } else {
                        this.props.handleMessage("No se pudo consultar el precio", "error")
                    }
                })
                .catch(error => {
                    this.props.handleMessage(error.message, "error");
                    this.setState({loadingPage: false});
                })
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    handleInputChange={this.handleInputChange}
                    handleSubmit={this.handleSubmit}
                    error={
                        {
                            "createValue": this.validator.message('createValue', this.state.editPrice.createValue, 'required|numeric'),
                            "renewValue": this.validator.message('renewValue', this.state.editPrice.renewValue, 'required|numeric'),
                            "transferValue": this.validator.message('transferValue', this.state.editPrice.transferValue, 'required|numeric'),
                            "restoreValue": this.validator.message('restoreValue', this.state.editPrice.restoreValue, 'required|numeric'),
                        }
                    }
                />
            );
        }
    }
));