import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import language from "../store/helpers/language"


import {logout} from '../store/actions/userActions';
import {loginSuccess} from '../store/actions/globalActions';
import {actionsToast} from "./actionsToast";
import {serviceApiResponseLogin} from "../services/request";
import jwtDecode from "jwt-decode";

const mapStateToProps = ({global: {language}, users, authentication}) => ({
    users,
    authentication,
    language
});

const mapDispatchToProps = dispatch => bindActionCreators({
    logout,
    loginSuccess
}, dispatch);


export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.state = {
                username: '',
                password: '',
                loading: false,
                loggedIn: false,
            };

            this.handleChange = this.handleChange.bind(this);
            this.handleSubmit = this.handleSubmit.bind(this);
        }

        handleChange(e) {
            const {name, value} = e.target;
            this.setState({[name]: value});
        }

        handleSubmit(e) {
            e.preventDefault();

            const {username, password} = this.state;
            if (username && password) {
                // this.props.login(username, password, this.props);
                const data = {
                    username,
                    password
                };
                this.setState({loading: true});
                serviceApiResponseLogin(data, "login_check", "POST")
                    .then(response => {
                        this.setState({loading: false});
                        if (response.token) {
                            const decoded = jwtDecode(response.token);
                            const user = {
                                user: decoded,
                                token: response.token,
                            };
                            let valid = false;
                            decoded.roles.map(role => {
                                if (role === "ROLE_ADMIN") {
                                    valid = true;
                                }
                            });

                            if (!valid) {
                                this.setState({loading: false});
                                this.props.enqueueSnackbar("Acceso denegado", {
                                    variant: 'error',
                                    action: actionsToast(this.props)
                                })
                                return
                            }

                            //todo pasa bien como role_admin se deja acceder al sistema de manage
                            this.props.loginSuccess(user);
                            localStorage.setItem('user', JSON.stringify(user));
                            if (localStorage.getItem("checkout") === "SI") {
                                this.props.history.push("/checkout");
                                localStorage.setItem("checkout", "NO");
                            } else {
                                this.props.history.push("/Dashboard");
                            }
                        }
                    })
                    .catch(error => {
                        this.setState({loading: false});
                        this.props.enqueueSnackbar(error.message, {
                            variant: 'error',
                            action: actionsToast(this.props)
                        })
                    })
            } else {
                this.props.enqueueSnackbar(language[this.props.language].EmailPasswordRequired, {
                    variant: 'error',
                    action: actionsToast(this.props)
                })
            }
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    {...this.state}
                    handleChange={this.handleChange}
                    handleSubmit={this.handleSubmit}
                />
            );
        }
    }
));