import React, {Fragment} from 'react';
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import language from '../../../store/helpers/language';
import {CircularProgress, LinearProgress, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLocal from "../../local/InputLocal";
import withPricesEdit from "../../../providers/withPricesEdit";
import InputLocalFull from "../../local/InputLocalFull";

const docs = [
    {value: "CO", name: "Colombia"},
];

const styles = {
    welcome: {
        color: "#05092C",
    },

    title: {
        fontSize: "18px",
        fontWeight: "bold",
        lineHeight: "1",
    },
};

const EditPrice = (props) => {
    const {classes, handleInputChange, editPrice} = props;
    return (
        <Fragment>
            {props.loadingPage ?
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <LinearProgress/>
                    </Grid>
                </Grid>
                :
                <Grid container spacing={2}>
                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Valores"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit} autoComplete={"off"}>
                                    <Grid container spacing={1}>
                                        <InputLocalFull
                                            type={"text"}
                                            name={"tld"}
                                            id={"tld"}
                                            label={"Tld"}
                                            required={false}
                                            disabled={true}
                                            placeholder={"Tld"}
                                            value={editPrice.id || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"createValue"}
                                            id={"createValue"}
                                            label={"Valor"}
                                            required={true}
                                            placeholder={"Valor"}
                                            handleChange={handleInputChange}
                                            error={props.error.createValue !== undefined}
                                            value={editPrice.createValue || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"renewValue"}
                                            id={"renewValue"}
                                            label={"Valor de renovación"}
                                            required={true}
                                            placeholder={"Valor de renovación"}
                                            handleChange={handleInputChange}
                                            error={props.error.renewValue !== undefined}
                                            value={editPrice.createValue || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"transferValue"}
                                            id={"transferValue"}
                                            label={"Valor de transferencia"}
                                            required={false}
                                            placeholder={"Valor de transferencia"}
                                            error={props.error.transferValue !== undefined}
                                            handleChange={handleInputChange}
                                            value={editPrice.transferValue || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"restoreValue"}
                                            id={"restoreValue"}
                                            label={"Valor de restauración"}
                                            required={false}
                                            placeholder={"Valor de restauración"}
                                            error={props.error.restoreValue !== undefined}
                                            handleChange={handleInputChange}
                                            value={editPrice.transferValue || ""}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={8} md={8} sm={12} xs={12}>
                        <Card>
                            <CardHeader
                                disableTypography={true}
                                className={classes.title}
                                title={"Promoción"}
                            />
                            <Divider light={true}/>
                            <CardContent>
                                <form onSubmit={props.handleSubmit} autoComplete={"off"}>
                                    <Grid container spacing={1}>
                                        <InputLocalFull
                                            type={"date"}
                                            name={"promoFrom"}
                                            id={"promoFrom"}
                                            label={"Fecha desde"}
                                            handleChange={handleInputChange}
                                            value={editPrice.promoFrom || ""}
                                        />
                                        <InputLocalFull
                                            type={"date"}
                                            name={"promoTo"}
                                            id={"promoTo"}
                                            label={"Fecha hasta"}
                                            handleChange={handleInputChange}
                                            value={editPrice.promoTo || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"createPromoValue"}
                                            id={"createPromoValue"}
                                            label={"Valor"}
                                            placeholder={"Valor"}
                                            handleChange={handleInputChange}
                                            value={editPrice.createPromoValue || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"renewPromoValue"}
                                            id={"renewPromoValue"}
                                            label={"Valor renovación"}
                                            placeholder={"Valor renovación"}
                                            handleChange={handleInputChange}
                                            value={editPrice.renewPromoValue || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"transferPromoValue"}
                                            id={"transferPromoValue"}
                                            label={"Valor de transferencia"}
                                            placeholder={"Valor de transferencia"}
                                            handleChange={handleInputChange}
                                            value={editPrice.transferPromoValue || ""}
                                        />
                                        <InputLocal
                                            type={"number"}
                                            name={"restorePromoValue"}
                                            id={"restorePromoValue"}
                                            label={"Valor de restauración"}
                                            placeholder={"Valor de restauración"}
                                            handleChange={handleInputChange}
                                            value={editPrice.restorePromoValue || ""}
                                        />
                                    </Grid>
                                </form>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid item lg={4} md={4} sm={12} xs={12}>
                    </Grid>

                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => props.handleSubmit(e)}
                        >
                            {props.loading ?
                                <CircularProgress
                                    size={24}
                                    color={"secondary"}/>
                                : "Guardar"
                            }
                        </Button>
                    </Grid>
                    <Grid item lg={4} md={4} sm={12} xs={12}>
                        <Button
                            type="button"
                            variant="outlined"
                            fullWidth
                            color="inherit"
                            className={classes.submit}
                            onClick={() => props.history.push("/Prices")}
                            disabled={props.loading}
                        >
                            Cancelar
                        </Button>
                    </Grid>

                    <Grid item xs={12}>
                    </Grid>
                </Grid>
            }
        </Fragment>
    )
};

export default withPricesEdit(withStyles(styles)(EditPrice));