import React from 'react';
import {Route} from 'react-router-dom';
import {Login} from "./index";
import Remember from "./Login/remember";

export const PrivateRoute = ({component: Component, ...rest}) => (

    <Route {...rest} render={props => (
        localStorage.getItem('user')
            ? <Component {...props}/>
            :
            props.history.location.pathname === "/remember" ?
                <Remember/> :
                <Login/>
    )}/>
)