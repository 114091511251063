import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {
    changeModProd,
    changeLanguage,
    changeCurrency,
    requestChangeOrder,
    requestChangeOrderItem,
    requestGetOrder,
} from '../store/actions/globalActions';

import {
    logout
} from '../store/actions/userActions';
import {serviceApiResponse, serviceApiResponseDetele} from "../services/request";
import {actionsToast} from "./actionsToast";

const mapStateToProps = ({global: {userActive, language, currency, order, orderItems}}) => ({
    userActive,
    language,
    currency,
    order,
    orderItems,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    changeModProd,
    changeLanguage,
    changeCurrency,
    logout,
    requestChangeOrder,
    requestChangeOrderItem,
    requestGetOrder
}, dispatch);

export default WrappedComponent => (connect(mapStateToProps, mapDispatchToProps)(
    class extends React.PureComponent {

        constructor(props) {
            super(props);

            this.deleteOrdeItem = this.deleteOrdeItem.bind(this);
            this.changePeriod = this.changePeriod.bind(this);
        }

        deleteOrdeItem(item, key) {
            serviceApiResponseDetele(`order_items/${item.id}`, "", "DELETE", false)
                .then(response => {
                    if (response.status === 204) {
                        let order = Object.assign({}, this.props.order);
                        order.items.splice(key, 1);
                        order.total -= item.total;
                        this.props.requestChangeOrder(order);
                        this.props.requestChangeOrderItem(order.items.length);
                    } else {
                        this.handleMessage(response.statusText, "error");
                    }
                })
                .catch(error => {
                    this.handleMessage(error.message, "error");
                });
        }

        changePeriod(item, key, period) {
            serviceApiResponse({period: parseInt(period)}, `order_items/${item.id}`, "PUT", false, false)
                .then(response => {
                    if (response.id) {
                        let order = Object.assign({}, this.props.order);
                        order.total = (order.total - order.items[key].total);
                        order.items[key].total = order.items[key].unitPrice * parseInt(period);
                        order.items[key].period = period;
                        order.total = order.total + order.items[key].total;
                        this.props.requestChangeOrder(order);
                    }
                })
                .catch(error => {
                    this.handleMessage(error.message, "error");
                })
        }

        handleMessage(message, type) {
            this.props.enqueueSnackbar(message, {
                variant: type,
                action: actionsToast(this.props)
            });
        }

        render() {
            return (
                <WrappedComponent {...this.props} deleteOrdeItem={this.deleteOrdeItem}
                                  changePeriod={this.changePeriod}/>
            );
        }
    }
));